/* eslint-disable no-nested-ternary */
// const prod = process.env.NODE_ENV === 'production';

const live = process.env?.REACT_APP_DEV_LIVE === 'live';
const staging = process.env?.REACT_APP_DEV_LIVE === 'staging';

const baseUrl = live
  ? 'https://api.footbizz.in'
  : staging
    ? 'https://staging-api.footbizz.in'
    : 'http://192.168.0.113:9000';

export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: baseUrl,
  csvUrl: `${baseUrl}/SampleCsv`,
  sailsUrl: baseUrl,

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'zbs5etcfyasms34yp3h0spt1h722agxsaejl5ph5ftdkiziu',
  dashboard: '/dashboard',

  endpoints: {
    deleteUser: 'admin/users/user-delete',
  },
};
